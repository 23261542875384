
body {
    section {
        padding: 1rem 0;
    }

    .navbar-fixed-top {
        z-index: 1066;
        padding-top: .2rem;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5);

        .nav-item, .nav-link {
            color: $white;
            font-weight: bold;
            font-size: 15px;
            text-decoration: none;

            &:hover, &:focus {
                color: rgba($color: $white, $alpha: .75);
            }

            .dropdown-item {
                &:hover {
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }

    .panel {
        .title {
            box-shadow: 0 0 3px #ccc;
        }
    }

    // About Us Page
    #about-us {
        .header {
            background-image: url(../images/about-us-header.png);
        }
    }

    // Policy Pages
    #privacy-policy, #cookie-policy, #refund-policy, #terms-and-conditions {
        .header {
            background-image: url(../images/policy-header.png);
        }
    }

    #faq {
        .header {
            background-image: url(../images/motor-header.png);
        }
    }

    #claims {
        .header {
            background-image: url(../images/claims-header.png);
        }
    }

    .header {
        background-position: center;
        background-size: cover;
        content: ' ';
        min-height: 75vh;
    }

    // Home Page
    #landing-banner {
        .modal-content {
            display: contents;
        }

        .btn-close {
            right: 10px;
            top: 10px;
        }

        img {
            max-height: 700px;
        }

        @include media-breakpoint-down(lg) {
            .btn-close {
                right: 20px;
                top: 15px;
            }

            img {
                max-height: 650px;
                text-align: center;
            }
        }
    }

    #promo-carousel {
        .carousel-image {
            width: 100%;
            height: 82vh;
            max-height: 866px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }

    .products {
        margin-top: -100px;
        margin-bottom: 50px;
        padding: 20px 0px;

        #product-card {
            z-index: 99;
        }
    }

    #coming-soon {
        .container-fluid {
            background-color: #f6f5f5;
        }

        @include media-breakpoint-down(md) {
            img {
                width: 75%;
            }
        }
    }

    #made-easy {
        .numbering {
            height: 2rem;
            width: 2rem;
            font-size: 1.3rem;
        }

        @include media-breakpoint-down(md) {
            .numbering {
                height: 1.5rem;
                width: 1.5rem;
                font-size: 1rem;
                padding-left: .5rem;
            }
        }
    }

    #info {
        background-image: url(../images/bg-info.png);
        background-position: center;
        background-size: cover;

        @include media-breakpoint-up(lg) {
            .description {
                width: 50%;
            }
        }
    }

    // Motor Pages
    #motor {
        padding: 0;

        .header {
            background-image: url(../images/motor-header.png);
            min-height: 69vh;

            .tag-line {
                padding-top: 100px;
            }
        }

        .form-control {
            background-color: #f6f5f5;
            text-indent: .625rem;
            border: 0;
        }

        #motor-details {
            .icon-motor {
                z-index: 3;
                margin-top: 24px;
                width: 135px;
            }

            .vehicle-details {
                z-index: 2;
                background-position: center;
                margin-top: -12px;
                padding-bottom: 30px;

                #vehicle-no {
                    border-radius: 2rem;
                    height: 3.4rem;
                }
            }

            @include media-breakpoint-up(lg) {
                .icon-motor {
                    margin-top: -15px;
                    width: 210px;
                }

                .vehicle-details {
                    padding-bottom: 68px;
                }
            }

            @include media-breakpoint-down(sm) {
                .icon-motor {
                    margin-top: 30px;
                    width: 120px;
                }
            }
        }

        #extend-info-form {
            margin-top: -85px;
            margin-left: 20px;

            #btn-clear {
                font-size: 20px;
                padding: 10px 20px;
            }

            #btn-continue {
                border-radius: 2rem;
                font-size: 20px;
                padding: 10px 30px;
            }
        }

        #partners {
            .insurers-container {
                padding: 20px 200px;
                background-image: url(../images/bg-partner.png);
            }

            @include media-breakpoint-down(md) {
                .insurers-container {
                    padding: 10px 30px;
                }
            }
        }
    }

    #vehicle-details, #compare, #add-ons, #policy-holder, #payment-summary, #payment-success {
        .header {
            background-image: url(../images/policy-header.png);
        }

        .opacity-25 {
            opacity: .25;
        }

        p {
            font-size: 16px;
        }
    }

    #compare {
        #landing-banner {
            .modal-content {
                display: contents;
            }

            .btn-close {
                right: 210px;
                top: 10px;
            }

            @media (max-width: 991px) {
                .btn-close {
                    right: 135px;
                    top: 10px;
                }
            }
        }

        .insurer-card {
            .insurer-logo {
                height: 100px;
            }
        }
    }

    // General
    #content-wrapper {
        .title {
            background-image: url(../images/bg-title.png);
            background-position: center;

            .about-title {
                font-size: 45px;
            }
        }
    }

    .footer-link {
        .vr {
            min-height: 20px;
            background-color: $secondary;
        }
    }

    .panel-tab {
        width: 95%;
    }

    .title {
        color: $blue;
        font-weight: bold;
    }

    .rounded {
        border-radius: 1rem !important;
    }

    button.rounded {
        border-radius: 10rem !important;
    }

    .rounded-end {
        border-bottom-right-radius: 1rem !important;
        border-top-right-radius: 1rem !important;
    }

    .rounded-start {
        border-top-left-radius: 1rem !important;
        border-bottom-left-radius: 1rem !important;
    }

    .negative-margin-5 {
        margin-top: -5rem;
    }

    .nm-10 {
        margin-top: -10rem;
    }

    .nm-15 {
        margin-top: -15rem;
    }

    .white-glow {
        -webkit-box-shadow: 0 0 20px #ccc;
        box-shadow: 0 0 20px #ccc;
    }

    .parsley-errors-list {
        color: $red;
        list-style-type: none;
        padding-left: 20px;
    }

    .loadingButton {
        position: relative;
        pointer-events: none;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: inherit;
            border-radius: 2rem !important;
        }

        &:after {
            font-family: "Font Awesome 6 Free";
            font-weight: 900;
            content: "\F1CE";
            font-size: 1.125rem;
            position: absolute;
            left: calc(50% - .5625rem);
            -webkit-animation: fa-spin 2s linear infinite;
            animation: fa-spin 2s linear infinite;
            border-radius: 2rem !important;
        }
    }

    .programmatically-hidden {
        display: none;
    }

    // Steps
    .bs-stepper {
        .bs-stepper-circle {
            width: 35px;
            height: 35px;
            font-size: 15px;
            border-radius: 3rem;
        }

        .stepper-label {
            width: 25%;
            bottom: 15px;
            font-size: 12px;
            white-space: pre-wrap;
        }

        @include media-breakpoint-up(md) {
            .bs-stepper-circle {
                width: 55px;
                height: 55px;
                font-size: 20px;
            }

            .stepper-label {
                bottom: 30px;
                font-size: 13px;
                white-space: pre-wrap;
            }
        }

        @include media-breakpoint-up(lg) {
            .bs-stepper-circle {
                width: 75px;
                height: 75px;
                font-size: 25px;
            }

            .stepper-label {
                bottom: 35px;
            }
        }
    }

    // Font Awesome
    .fa-15x {
        font-size: 1.5rem;
    }

    .btn:focus {
        &:not(.btn-link),
        &:not(.btn-buy) {
            background-color: $primary;
        }
    }
}
