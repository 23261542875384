// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Custom
@import "custom";

// Libraries
@import "aos/dist/aos.css";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "bs-stepper/dist/css/bs-stepper.min.css";
@import "select2/src/scss/core";
@import "select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.css";

// Frontend
@import "frontend";